'use client';
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
} from '@chakra-ui/react';
import type { MouseEvent } from 'react';
import { Button } from '~/components/core/Button';
import { useI18n } from '~/locales/client';
import { FontWeight } from '~/constants';
import { getCookieSettings } from '~/utils/cookieUtils';
import {
  CookieSettingType,
  CookieTypeEnum,
} from '~/types-and-enums/cookieTypes';

export type CookiePreferencesProps = {
  isModalOpen: boolean;
  onClose: () => void;
  handleCookieSettingClick: (setting: CookieSettingType) => void;
  handleConfirm: () => void;
  selectedCookies: CookieTypeEnum[];
  setSelectedCookies: (cookies: CookieTypeEnum[]) => void;
};

export default function CookiePreferences({
  isModalOpen,
  onClose,
  handleCookieSettingClick,
  handleConfirm,
  selectedCookies,
  setSelectedCookies,
}: CookiePreferencesProps) {
  const t = useI18n();

  return (
    <Modal isOpen={isModalOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('cookieBanner.modal.title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={8} pt={2} pb={16}>
          <Text>{t('cookieBanner.modal.description')}</Text>
          <Text textAlign="center" pt={4}>
            <Button
              size="sm"
              onClick={() => setSelectedCookies([CookieTypeEnum.Functional])}
            >
              {t('cookieBanner.modal.optOutExtras')}
            </Button>
          </Text>
          {getCookieSettings().map((setting) => (
            <Box
              mt={8}
              key={setting.type}
              display="flex"
              flexDirection="column"
            >
              <Box
                as="button"
                mb={2}
                display="flex"
                alignItems="center"
                gap={2}
                onClick={(e: MouseEvent) => {
                  e.preventDefault();
                  handleCookieSettingClick(setting);
                }}
              >
                <Text fontWeight={FontWeight.Bold} flex={1} textAlign="left">
                  {t(setting.title)}
                </Text>
                <Switch
                  colorScheme="brand-red"
                  isChecked={selectedCookies.includes(setting.type)}
                  isDisabled={setting.required}
                  onChange={(e) => {
                    handleCookieSettingClick(setting);
                  }}
                />
              </Box>
              <Text>{t(setting.description)}</Text>
            </Box>
          ))}
        </ModalBody>

        <ModalFooter gap={2} justifyContent="center">
          <Button mode="basic" onClick={handleConfirm}>
            {t('cookieBanner.modal.button.confirm')}
          </Button>
          <Button mode="transparent" onClick={onClose}>
            {t('cookieBanner.modal.button.cancel')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
