'use client';

import { sendGTMEvent } from '@next/third-parties/google';
import { setCookie } from 'cookies-next';
import { useAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';

import { acceptedCookiePolicyAtom, cookieModalAtom } from '~/atoms';

import CookieCard from './CookieCard';
import CookiePreferences from './CookiePreferences';
import { CookieStorageKey } from './constants';
import {
  getCookieSettings,
  getInitiallySelectedCookies,
} from '~/utils/cookieUtils';
import {
  AcceptedCookiePolicyEnum,
  CookieSettingType,
  CookieTypeEnum,
} from '~/types-and-enums/cookieTypes';

export function CookieBanner() {
  const [acceptedCookiePolicy, setAcceptedCookiePolicy] = useAtom(
    acceptedCookiePolicyAtom
  );

  const [isModalOpen, setIsModalOpen] = useAtom(cookieModalAtom);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialCookiePolicy = useMemo(() => acceptedCookiePolicy, []);
  const [selectedCookies, setSelectedCookies] = useState<CookieTypeEnum[]>(
    getInitiallySelectedCookies(acceptedCookiePolicy)
  );

  useEffect(() => {
    if (isModalOpen) {
      setSelectedCookies(getInitiallySelectedCookies(acceptedCookiePolicy));
    }
  }, [acceptedCookiePolicy, isModalOpen]);

  useEffect(() => {
    if (acceptedCookiePolicy !== initialCookiePolicy) {
      document.cookie = `${CookieStorageKey}=${acceptedCookiePolicy}; path=/; SameSite=Strict;`;
      setCookie(
        'hasAcceptedAnalytics',
        acceptedCookiePolicy.includes(CookieTypeEnum.Analytics)
      );
      setCookie(
        'hasAcceptedBehavioral',
        acceptedCookiePolicy.includes(CookieTypeEnum.Behavioral)
      );
      sendGTMEvent({
        event: 'E_setAcceptedCookiePolicy',
        user_cookies_hasAcceptedAnalytics: acceptedCookiePolicy.includes(
          CookieTypeEnum.Analytics
        ),
        user_cookies_hasAcceptedBehavioral: acceptedCookiePolicy.includes(
          CookieTypeEnum.Behavioral
        ),
      });
    }
  }, [initialCookiePolicy, acceptedCookiePolicy]);

  const currentCookiePolicy = useMemo(
    () =>
      getCookieSettings()
        .map((setting) => {
          if (setting.required) {
            return setting.type;
          }

          return selectedCookies.includes(setting.type) ? setting.type : null;
        })
        .filter(Boolean)
        .join('') as AcceptedCookiePolicyEnum,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCookies]
  );

  const onOpen = () => {
    setIsModalOpen(true);
  };

  const onClose = () => {
    setIsModalOpen(false);
  };

  const handleCookieSettingClick = (setting: CookieSettingType) => {
    if (setting.required) {
      return;
    }

    if (selectedCookies.includes(setting.type)) {
      setSelectedCookies(
        selectedCookies.filter((cookie) => cookie !== setting.type)
      );
    } else {
      setSelectedCookies([...selectedCookies, setting.type]);
    }
  };

  const handleConfirm = () => {
    setAcceptedCookiePolicy(currentCookiePolicy);
    onClose();
  };

  return (
    <>
      <CookieCard
        onOpen={onOpen}
        acceptedCookiePolicy={acceptedCookiePolicy}
        setAcceptedCookiePolicy={setAcceptedCookiePolicy}
      />
      <CookiePreferences
        isModalOpen={isModalOpen}
        onClose={onClose}
        handleCookieSettingClick={handleCookieSettingClick}
        handleConfirm={handleConfirm}
        selectedCookies={selectedCookies}
        setSelectedCookies={setSelectedCookies}
      />
    </>
  );
}
