'use client';

import { CacheProvider } from '@chakra-ui/next-js';
import { Provider as JotaiProvider } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { SessionProvider } from 'next-auth/react';
import '~/styles/index.css';
import { acceptedCookiePolicyAtom } from '~/atoms';
import { I18nProviderClient } from '~/locales/client';
import theme from '~/styles/theme';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { Tracking } from './_components/Tracking';
import { LocaleEnum } from '~/locales/resources';
import { AcceptedCookiePolicyType } from '~/types-and-enums/cookieTypes';

type ProviderProps = {
  children: React.ReactNode;
  locale: LocaleEnum;
  cookiePolicy: AcceptedCookiePolicyType;
};

export const Providers = ({
  children,
  locale,
  cookiePolicy,
}: ProviderProps) => {
  return (
    <>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <SessionProvider>
        <I18nProviderClient locale={locale}>
          <JotaiProvider>
            <CacheProvider>
              <ChakraProvider theme={theme}>
                <CookiePolicyInitiator cookiePolicy={cookiePolicy} />
                {children}
                <Tracking locale={locale as LocaleEnum} />
              </ChakraProvider>
            </CacheProvider>
          </JotaiProvider>
        </I18nProviderClient>
      </SessionProvider>
    </>
  );
};

const CookiePolicyInitiator = ({
  cookiePolicy,
}: {
  cookiePolicy: AcceptedCookiePolicyType;
}) => {
  useHydrateAtoms([[acceptedCookiePolicyAtom, cookiePolicy]]);
  return null;
};
