'use client';
import { useEffect } from 'react';
import { sendGTMEvent } from '@next/third-parties/google';
import { useSession } from 'next-auth/react';

import { checkUserIsEmployee } from '~/utils/generalUtils';
import { LocaleEnum } from '~/locales/resources';

type Props = {
  locale?: LocaleEnum;
};

export function Tracking({ locale }: Props) {
  const { data: session } = useSession();
  const user = session?.user;

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID && user) {
      sendGTMEvent({
        event: 'E_onLayoutLoad',
        user_id: user.id,
        user_name: user.name,
        user_email: user.email,
        user_isEmployee: checkUserIsEmployee(user.email),
        user_locale: locale,
      });
    }
  }, [user, locale]);

  return null;
}
