import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { SearchResponse } from '~/app/[locale]/home/store/requests';
import { Suggestion } from '~/app/[locale]/home/store/_components/Search';
import {
  AcceptedCookiePolicyEnum,
  AcceptedCookiePolicyType,
} from '~/types-and-enums/cookieTypes';

type StorageFlags = {
  showDebugFE: boolean;
};
export const storageFlags = atomWithStorage<StorageFlags>('flags', {
  showDebugFE: false,
});

export const cookieModalAtom = atom<boolean>(false);

export const acceptedCookiePolicyAtom = atom<AcceptedCookiePolicyType>(
  AcceptedCookiePolicyEnum.None
);

export const wantsStoreIsLiveNotification = atom<boolean>(false);

export const openQuotesCountAtom = atom<number>(0);

export const isSearchActiveAtom = atom<boolean>(false);
export const isLoadingAtom = atom<boolean>(false);

export const searchValueAtom = atom<string>('');
export const searchSuggestionAtom = atom<Suggestion[] | null>(null);

export const searchResponseAtom = atomWithStorage<SearchResponse | null>(
  'searchResponse',
  null
);

export type Filters = {
  category?: string;
  priceRange?: [number, number];
  brand?: string;
  deliveryTime?: string;
};

export const filtersAtom = atom<Filters>({});

export const numberOfDisplayedComments = atom<number>(0);
